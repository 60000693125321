import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../components/MainPage.vue'
import PickTrack from '../components/PickTrack.vue'

Vue.use(VueRouter)

import user from '@/api/user' 

const routes = [
    { path: '/', redirect: {name:'picktrack'} },
    {
        path: '/login',name:'login',component:()=>import('../components/Login.vue'),
    },
    {
        path: '/main',name:'main',component:MainPage,
        //子路由
        children:[
            {
                path: 'picktrack',
                name:'picktrack',
                component:PickTrack
            },
            {
                path:'novel',
                name:'novel',
                component:()=>import('../components/Novel.vue'),
                beforeEnter: (to, from, next) => {
                    const token = localStorage.getItem('token');
                    const isLogin = !!token;//有无token
                    if (isLogin) { //本地有token
                        //向后端发送token验证
                       user.verify(token).then(res => {
                            if (res.data.code == '200') {
                                console.log("已登录，放行")
                                next();
                                return;
                            }
                            if (res.data.code == '400') {//无效token
                                console.log("无效token,去登录页")
                                localStorage.removeItem('token');
                                // localStorage.removeItem('uid');
                                // localStorage.removeItem('name');
                                router.push('/login',()=>{}, ()=>{});
                                return;
                            }     
                        })
                    }
                    if (!isLogin) {  //未登录，跳转到登录页
                        router.push('/login',()=>{}, ()=>{});
                        return
                    }
                },
            }
        ]
    },
]

const router = new VueRouter({
    routes
})


export default router