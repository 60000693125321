import axios from 'axios';

const service = axios.create({
    baseURL: 'http://tools.atregret.top/api',
    //baseURL: 'http://127.0.0.1:7000',
    timeout: 5000
    });

export default service;

///etc/nginx/sites-available