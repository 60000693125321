import service from "@/utils/axios";

export default{
    login(userInfo){
        return service.get('/login',{params:userInfo});
        //return service.post('/loginSave',userInfo);
    },
    verify(token){
        return service.get('/verify',{params:{'token':token}});
    },
    send_coordinate(path_arr){
        return service.post('/',path_arr,{responseType: 'blob'});
    },
    send_novel_info(novel_info){
        return service.post('/add_novel',novel_info);
    },
    get_novel_list(){
        return service.get('/get_novel');
    },
    get_novel_name(){
        return service.get('/get_novel_name');
    },
    update_novel_info(novel_info){
        return service.post('/update_novel',novel_info);
    },
    delete_novel_info(novel_info){
        return service.post('/delete_novel',novel_info);
    }

}