<template>
  <el-container>
    <el-header style="height: 100%;">
      <el-row>
        <el-col :span="24">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">轨迹拾取</el-menu-item>
        <el-submenu index="2">
          <template slot="title">敬请1期待</template>
          <el-menu-item index="2-1">选项1</el-menu-item>
          <el-menu-item index="2-2">选项2</el-menu-item>
          <el-menu-item index="2-3">选项3</el-menu-item>
          <el-submenu index="2-4">
            <template slot="title">选项4</template>
            <el-menu-item index="2-4-1">选项1</el-menu-item>
            <el-menu-item index="2-4-2">选项2</el-menu-item>
            <el-menu-item index="2-4-3">选项3</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item index="3" disabled>敬请期待</el-menu-item>
        <el-menu-item index="4"><a href="https://smallpdf.com/cn" target="_blank">pdf处理</a></el-menu-item>
        <el-menu-item index="5">小说</el-menu-item>
      </el-menu>
        </el-col>
      </el-row>
      
    </el-header>
    <el-main>
      <el-row>
        <el-col :span="22" :offset="1" style="height: 500px;"><router-view></router-view></el-col>
      </el-row>
        
    </el-main>
    <el-footer>

      <a href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2024048181号-1</a>
    </el-footer>
  </el-container>
</template>

<script>

export default {
  name: 'MainPage',
  data() {
    return {
      activeIndex: '1'
    }
  },
  methods: {
    handleSelect(tab, event){
      if (tab == '1'){
        this.$router.push({path:'/main/picktrack'},()=>{}, ()=>{})
      }
      if(tab == '5'){
        this.$router.push({path:'/main/novel'},()=>{}, ()=>{})
      }
      console.log(tab, "---",event)
      console.log('handleSelect')
    }
  },
  mounted(){
    
    console.log('mounted')
    if (this.$route.path == '/main/picktrack'){
      this.activeIndex = '1'
    }
    if (this.$route.path == '/main/novel'){
      this.activeIndex = '5'
    }
  }
}

</script>

<style scoped>
a{text-decoration:none}
</style> 